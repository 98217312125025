<template>
  <div class="container is-max-desktop has-text-centered px-4" style="height: 100%">
    <div
      class="columns is-marginless is-justify-content-center"
      style="height: 100%">
      <img width="300" src="@/assets/images/verify-appointment.svg">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { VerifyAppointment } from '@/api/appointment';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['email']),
  },
  methods: {
    ...mapMutations({
      setUser: 'SET_USER',
    }),
  },
  async mounted() {
    if (!(this.$route.query.reference && this.$route.params.appointmentId)) {
      this.$router.push('/');
      return;
    }
    const loadingComponent = this.$buefy.loading.open();
    try {
      await VerifyAppointment(
        this.$route.params.appointmentId,
        this.$route.query.reference,
      );
      loadingComponent.close();
      this.$router.push({ name: 'Appointment' });
    } catch (error) {
      loadingComponent.close();
      //
    }
  },
};
</script>

<style>

</style>
