import HTTP from './HTTP';

const URL_BASE = 'appointment/';

export async function CreateAppointmentPatient(data) {
  const response = await HTTP.post(URL_BASE + 'create-appointment-patient/', data);
  return response;
}
export async function CreateAppointmentAdmin(data) {
  const response = await HTTP.post(URL_BASE + 'create-appointment-admin/', data);
  return response;
}
export async function UpdateAppointmentAdmin(appointmentId, data) {
  const response = await HTTP.put(URL_BASE + 'update-appointment/' + appointmentId, data);
  return response;
}
export async function UpdateAppointmentPatient(appointmentId, data) {
  const response = await HTTP.put(URL_BASE + 'update-appointment-patient/' + appointmentId, data);
  return response;
}
export async function GetAppointmentsUser(upcoming) {
  const response = await HTTP.get(URL_BASE + 'get-appointments-user/', {
    params: {
      upcoming,
    },
  });
  return response;
}
export async function GetAppointment(id) {
  const response = await HTTP.get(URL_BASE + 'get-appointment/' + id);
  return response;
}
export async function VerifyAppointment(appointmentId, reference) {
  const response = await HTTP.get(URL_BASE + 'verify-appointment/' + appointmentId + '/' + reference);
  return response;
}
export async function PayAppointment(appointmentId) {
  const response = await HTTP.get(URL_BASE + 'pay-appointment/' + appointmentId);
  return response;
}
export async function AssignDoctor(appointmentId, doctorId) {
  const response = await HTTP.get(URL_BASE + 'assign-doctor/' + appointmentId + '/' + doctorId);
  return response;
}
export async function AcceptAppointment(appointmentId) {
  const response = await HTTP.get(URL_BASE + 'accept-appointment/' + appointmentId);
  return response;
}
export async function RejectAppointment(appointmentId, doctorDesc) {
  const response = await HTTP.post(URL_BASE + 'assign-doctor/' + appointmentId, {
    doctorDesc,
  });
  return response;
}
export async function customUpdateAppointmentAdmin(appointmentId, data) {
  const response = await HTTP.put(URL_BASE + 'custom-update-appointment/' + appointmentId, data);
  return response;
}
